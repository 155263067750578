import { render, staticRenderFns } from "./Measure.vue?vue&type=template&id=52d82f3e&scoped=true&"
import script from "./Measure.vue?vue&type=script&lang=js&"
export * from "./Measure.vue?vue&type=script&lang=js&"
import style0 from "./Measure.vue?vue&type=style&index=0&id=52d82f3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d82f3e",
  null
  
)

export default component.exports