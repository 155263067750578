<template>
  <div id="top" class="content">
    <div class="middled"> 

      <div class="row searchrow">
        <div class="column">
          <div class="breadcrumbs">
            <router-link :to="{ name: 'dashboards'}">{{ account.account_name }}</router-link>
          </div>
        </div>
        <div class="column">
          <searchbar />
        </div>
      </div>

      <div v-if="loading" style="text-align:center;">Loading...</div>
      <div v-else>
        <div>
          <div class="row">
            <div class="column">

              <h1 style="text-transform:capitalize;text-align:center;">
                {{ scorecard.scorecard_name }}
              </h1>

              <div style="text-align:center;white-space: break-spaces;font-size: .8em;max-width: 600px;margin:auto;">
                <span v-show="!scorecard.deleted" v-html="scorecard.scorecard_description" v-linkified />
                <span v-show="scorecard.deleted" style="color:#CC0000;">
                  Has been archived
                  <a href="#" @click.prevent="showEdit = true;showModal = false;">
                    (Reactivate?)
                  </a>
                </span>
              </div>

              <div style="margin:20px 0;white-space: break-spaces;font-size: .8em;" v-show="false">
                <template v-if="summary_stream.length > 0">
                  <div style="border: 1px solid #ccc;background:#fff;padding:10px;border-radius: 10px;">
                    <div style="font-weight: bold;color:#333;margin-bottom:8px;">{{ scorecard.scorecard_name }} status as of {{ summary_date | moment("MMM DD, YYYY") }}: </div>
                    <span v-for="(message,index) in summary_stream" v-bind:key="index">{{ message.text }}</span>
                  </div>
                </template>
                <template v-else>
                  <div style="text-align: center;">
                    <a href="#" @click.prevent="streamResponse(summary)">
                      <img :src="require('@/assets/ic_sparkle.png')" width="18" height="18" alt="AI Summary" title="Generate AI Summary" style="cursor:pointer;" />
                      AI Summary
                    </a>
                  </div>
                </template>
                <div style="text-align: right;margin-top:20px;" v-show="summary_stream.length > 0">
                  <a href="#" v-on:click.prevent="summarizeStatus" :disabled="summary_waiting.length > 0">
                    Regenerate Summary
                  </a>
                  <p v-if="summary_waiting.length > 0" class="tiny">{{ summary_waiting }}</p>
                  <p v-else class="tiny">Generated using GPT-4o</p>
                </div>
              </div>

              <div style="text-align: center;padding:20px;" v-show="scorecard.public">
                <button v-on:click.prevent="cloneScorecard" :disabled="cloning" style="padding:10px 20px;">Clone this Board</button>
              </div>

              <div class="smnav" id="categories">
                <img v-show="false" v-if="(account.user_id === user.user_id) && categories.length > 1" :src="require('@/assets/ic_drag.png')" @click="sortCategories = true;showModal = false;" width="28" height="28" alt="Sort Categories" title="Sort Categories" style="cursor:pointer;" />                    
                <img :src="require('@/assets/ic_sparkle.png')" width="24" height="24" alt="AI Chat" style="padding:2px;margin:0 4px;cursor: pointer;border:2px solid #0099FF;border-radius: 50%;" v-on:click="showChat = true" title="Chat with AI" />
                <img v-if="(account.account_id === scorecard.account_id)" :src="require('@/assets/ic_edit.png')" @click="showEdit = true;showModal = false;" width="28" height="28" alt="Edit" title="Edit Board" style="cursor:pointer;" />                    
                <router-link v-if="(account.account_id === scorecard.account_id)" :to="{name: 'new-category', params: { scorecard_id: scorecard.scorecard_id }}">
                  <img :src="require('@/assets/ic_add.png')" width="28" height="28" alt="Add Category" title="Add Category" style="cursor:pointer;" />
                  <span v-show="categories.length == 0"> &larr; Add categories here</span>
                </router-link>
                <div class="quick-buttons" v-if="(categories.length > 1) && (scorecard.scorecard_id > 0)" >
                  <a class="quickbutton" href="#tasks">View Tasks</a>
                  <a class="quickbutton" href="#categories" @click="expandAll" v-show="!expanded">Show Tiles</a>
                  <a class="quickbutton" href="#top" @click="expandAll" v-show="expanded">Hide Tiles</a>
                  <a class="quickbutton" href="#status">Review Activity</a>
                </div>
              </div>

              <div v-if="(categories.length < 1) && (scorecard.scorecard_id > 0)" style="max-width:500px;border:1px solid #ccc;padding:20px;margin:20px auto;">
                <h2 style="text-align:center;">
                    <span :class="{highlightyellow: categories.length < 1}">Start with categories.</span>
                </h2>
                <p style="text-align: center;">
                  Categories are like folders that help you focus.
                  Start by making a few categories, you can edit them later.
                </p>
                <p style="text-align:center;">
                  <router-link v-if="(account.account_id === scorecard.account_id)" :to="{name: 'new-category', params: { scorecard_id: scorecard.scorecard_id }}">
                    <button style="padding:10px 20px;">Make your first category</button>
                  </router-link>
                </p>
              </div>
            </div>
          </div>

          <div class="categories" v-if="categories.length > 0">
            <div class="row">
              <div class="column cat" v-for="category in categories" :key="category.category_id">
                <div class="categorycard" style="background: #fff;border-top:1px solid #333;" >
                  <div class="nextarrow">
                    <img :src="require('@/assets/ic_arrow_down.png')" @click="toggleExpansion(category.category_id)" :class="{ arrow_up: !isExpanded(category.category_id) }" alt="View More" style="cursor:pointer;" />
                  </div>
                  <router-link class="catname" :to="{name: 'category', params: { scorecard_id: category.scorecard_id, category_id: category.category_id }}">
                    <img v-show="isExpanded(category.category_id)" :src="require('@/assets/ic_folder.png')" :alt="category.category_name" width="21" height="21" align="left" style="margin-right:2px;" />
                    <img v-show="!isExpanded(category.category_id)" :src="require('@/assets/ic_folder_closed.png')" :alt="category.category_name" width="21" height="21" align="left" style="margin-right:2px;" />
                    <div style="overflow:hidden;">{{ category.category_name | truncate(85, '...') }}</div>
                  </router-link>
                  <div class="catdesc" @click="toggleExpansion(category.category_id)" >
                    {{ category.category_description ? category.category_description : 'No description' | truncate(85, '...') }}
                  </div>
                  <div class="nextarrow" @click="toggleExpansion(category.category_id)" style="cursor:pointer;font-size:.8em;line-height: 1.7em;padding-left:6px;">
                    {{ category.measures ? category.measures.length : 0 }} Tiles
                  </div>
                </div>

                <div class="measurebox" v-show="isExpanded(category.category_id)">
                  <CategoryMeasures v-bind:scid="scid" v-bind:cid="category.category_id" />
                </div>

                <div v-show="toggle">
                    <div class="colbox">
                      <p>{{category.category_description}}</p>
                    </div>
                </div>
                <div class="colboxbottom" v-show="isExpanded(category.category_id)"></div>
              </div>

            </div>
          </div>          
          <div v-show="false" v-if="(account.account_id === scorecard.account_id) && (!scorecard.public)" style="margin:6px 2px;text-align:center;padding:10px;background:#eee;">
            <router-link :to="{name: 'new-category', params: { scorecard_id: scorecard.scorecard_id }}">
              <div><img style="width:28px;height:28px;vertical-align:middle;" :src="require('@/assets/ic_add.png')" alt="Add Category" /> Add More Categories</div>
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="error" class="alert alert-danger">{{error}}</div>

      <div>
        <div id="chart" v-if="categories.length > 2">
          <apexchart type="radar" height="400" :options="chartOptions" :series="series" :key="catkey"></apexchart>
        </div>
      </div>

      <div id="tasks" class="smnav" v-if="snapshots.length > 0">
        <h2>Tasks:</h2>
        <div class="quick-buttons">
          <a href="#" @click.prevent="showAddTask = true" v-if="categories.length > 0" style="text-decoration: none;">
            <button style="padding: 6px 12px;">New Task</button>
          </a>
          <a class="quickbutton" href="#top">
            <span>Top</span>
          </a>
        </div>
      </div>

      <div style="margin-top:20px;" v-if="snapshots.length > 0">
        <div>
          <ScorecardTasks v-bind:scid="scid" v-bind:user_id="user.user_id" v-bind:category_id="0" :key="taskrender" />
        </div>
      </div>

      <div id="status" v-if="snapshots.length > 0" style="border-top:3px solid #ccc;">

        <div class="section-bar smnav" v-if="snapshots.length > 0">
          <h2>Activity:</h2>
          <div class="quick-buttons" v-if="(categories.length > 1) && (scorecard.scorecard_id > 0)" >
            <a class="quickbutton" href="#top">Top</a>
          </div>
        </div>


        <div class="tiny">
          <a href="#status" @click="notescore = 6" :class="{activefilter : notescore == 6 }">None</a>
          |
          <a href="#status" @click="notescore = 0" :class="{activefilter : notescore == 0 }">Recent</a>
          |
          <a href="#status" @click="notescore = 1" :class="{activefilter : notescore == 1 }">On Hold</a>
          |
          <a href="#status" @click="notescore = 2" :class="{activefilter : notescore == 2 }">Behind</a>
          |
          <a href="#status" @click="notescore = 3" :class="{activefilter : notescore == 3 }">On Track</a>
          |
          <a href="#status" @click="notescore = 4" :class="{activefilter : notescore == 4 }">Almost Done</a>
          |
          <a href="#status" @click="notescore = 5" :class="{activefilter : notescore == 5 }">Completed</a>
        </div>

        <div style="margin-top:10px;">
          <div v-for="note in filteredSnapshots" :key="note.id">
            <div style="padding:0;margin:10px 0;border:1px solid #ccc;">
              <div class="tiny" style="padding:8px;color:#333;background: #fff;">
                <div v-bind:class="bulletcolor(note.rank_score)" style="float:left;text-align:center;color:#fff;font-weight:bold;width:16px;height:16px;margin-right:8px;border-radius: 50%;"></div>
                <strong>
                  <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}`" style="text-decoration:none;color:#333;">
                    {{note.category_name}} 
                  </router-link> :
                  <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}/measures/${note.measure_id}`" :title="note.measure_name" style="text-decoration:none;color:#333;">
                    {{ note.measure_name }}
                  </router-link>
                </strong>
              </div>
              <div style="white-space: break-spaces;padding:5px 0 15px 32px;background: #fff;" v-if="note.snapshot_notes">
                <span v-html="note.snapshot_notes" v-linkified />
              </div>
              <div style="padding:5px 0 15px 32px;background: #fff;" v-else>
                <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}/measures/${note.measure_id}`" :title="note.benchmark_description" style="text-decoration:none;">
                  {{ note.benchmark_description }}
                </router-link>
              </div>
              <div class="tiny" style="padding:8px;background: #efefef;">
                {{ note.created_at | moment("MMM DD, YYYY") }}
                &bull;
                <span v-if="note.user_name">{{ note.user_name + ' &bull; '}}</span>
                <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}/measures/${note.measure_id}`" :title="note.benchmark_description" style="text-decoration:none;">
                  Change Status...
                </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div style="text-align:left;margin-top:20px;" v-if="snapshots.length > 0">
        
        <div class="section-bar smnav" v-if="snapshots.length > 0">
          <h2>Email Status Report:</h2>
          <div class="quick-buttons" v-if="(categories.length > 1) && (scorecard.scorecard_id > 0)" >
            <a class="quickbutton" href="#top">Top</a>
          </div>
        </div>
        
        <p class="tiny">
          Select who should get the email report, then click the "Send Email" button.
        </p>
        <div class="collabs">
          <div v-if="members">
            <div class="row" v-for="(member,index) in members" :key="index">
              <div>
                <input type="checkbox" @change="toggleemlist(member,$event)">
              </div>
              <div style="line-height:1.8rem;padding-left:4px;margin-bottom:10px;">
                {{member.member_first_name}} {{member.member_last_name}} <span v-if="member.member_id === account.user_id">(Owner)</span>
              </div>
            </div>
          </div>
          <div v-else>
            You're the only member
          </div>
        </div>
        <button v-on:click="sendStatus" :disabled="emlist.length < 1" style="padding:10px 20px;">Send Email</button>
        <transition name="fade">
          <div v-show="emsent" style="margin:20px;">
              {{ emsent }}
          </div>
        </transition>
      </div>

      <div style="text-align:left;margin-top:20px;font-size: .8em;" v-show="snapshots.length">
        
        <div class="section-bar smnav" v-if="snapshots.length > 0">
          <h2>Executive Summary:</h2>
          <div class="quick-buttons">
            <a class="quickbutton" href="#top">Top</a>
          </div>
        </div>

        <div v-if="summary" style="margin:20px 0px;padding:20px;background: #fff;border:1px solid #eee;border-radius: 4px;">
          <div v-if="summary.length > 180">
            <div style="white-space: break-spaces;">
              <span style="font-weight: bold;">{{ summary_date | moment("MMM DD, YYYY") }}: </span>
              <template v-if="summary_stream.length > 0">
                  <span v-for="(message,index) in summary_stream" v-bind:key="index">{{ message.text }}</span>
                </template>
                <span v-else>{{ summary }}</span>
            </div>
          </div>
          <div v-else>
            <div>
              <span style="font-weight: bold;">{{ summary_date | moment("MMM DD, YYYY") }}: </span>
              <template v-if="summary_stream.length > 0">
                  <span v-for="(message,index) in summary_stream" v-bind:key="index">{{ message.text }}</span>
                </template>
                <span v-else>{{ summary }}</span>
            </div>
          </div>
        </div>

        <div style="text-align: left;">
          <a href="#" v-on:click.prevent="summarizeStatus" :disabled="summary_waiting.length > 0">
            <template v-if="summary">
              Regenerate Executive Summary
            </template>
            <template v-else>
              Generate Executive Summary
            </template>
          </a>
          <p v-if="summary_waiting.length > 0" class="tiny">{{ summary_waiting }}</p>
          <p v-else class="tiny">Generated using Foclarity AI</p>
        </div>

      </div>

      <div>
        <p class="tiny" style="text-align:center;margin-top:20px;" v-show="false">
          <a href="#" @click.prevent="help = true">Send feedback</a>
        </p>

        <div v-show="help">
          <div class="page-message" v-show="!loading">
            <div>
              <h4>Feedback:</h4>
              <div class="form-grp">
                <textarea v-model="message" name="message" />
              </div>
              <div class="form-grp">
                <button class="btn btn-primary wide" :disabled="submitted" v-on:click="contactSupport">Send</button>&nbsp;
                <a href="#" v-on:click.prevent="help = false"> Cancel </a>
                <img v-show="submitted" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                <div v-if="emsg" class="emsg">{{emsg}}</div>
                <div v-if="error" class="alert alert-danger">{{error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="snapshots.length > 0" style="margin:30px auto;max-width:370px;" v-show="false">
        <p style="padding:0px 8px;">Invite collaborators:</p>
        <div class="row" style="max-width:370px;">
          <div class="column" style="flex-grow:4;">
            <input type="text" v-model="invite_email" placeholder="Enter email">
          </div>
          <div class="column" style="min-width:100px;padding-top:15px;">
            <button class="btn-add" style="width:100px;" :disabled="inviting" v-on:click.prevent="makeinvite">Invite</button>
          </div>
        </div>
        <transition name="fade">
          <div v-show="show_invite_message">
              {{ invite_message }}
          </div>
        </transition>
      </div>

      <modal v-if="showChat" @close="showChat = false">
        <template v-slot:header>
          <div style="font-weight: bold;">
            <div style="width:30px;height:30px;display:inline-block;vertical-align:middle;text-align:center;padding:2px;margin-right:10px;" >
              <img style="transform: rotateY(0deg) rotate(135deg);border-radius:50%;width:26px;height:26px;" :src="require('@/assets/foclarity-icon.png')" alt="Foclarity AI" />
            </div>
            <div style="display:inline-block;vertical-align:middle;" >
              Foclarity.AI
            </div>
          </div>
        </template>
        <template v-slot:body>
          <ChatByTopic :scid="scid" :cid="0" :mid="0" :user="user" :account="account" :topic="scorecard.scorecard_name" />
        </template>
        <template v-slot:footer>
          <div style="text-align: center;" class="tiny">Beta v1.3</div>
        </template>
      </modal>

      <!-- use the modal for draggable category sorting, pass in the prop -->
      <modal v-if="sortCategories" @close="sortCategories = false">
        <template v-slot:header>
          <h3>{{ scorecard.scorecard_name }}</h3>
        </template>
        <template v-slot:body>
          <div class="categories" v-if="categories.length > 0">

            <draggable v-model="categories">

              <div class="row" v-for="category in categories" :key="category.category_id">
                <div class="column cat">
                  <div class="categorycard" style="cursor: pointer;">
                      <img :src="require('@/assets/ic_drag.png')" alt="drag" width="21" height="21" align="left" style="margin-right:2px;" />
                      <div class="catname" style="overflow:hidden;" >{{ category.category_name | truncate(85, '...') }}</div>
                      <img :src="require('@/assets/ic_drag.png')" alt="drag" width="21" height="21" align="left" style="margin-right:2px;" />
                  </div>
                </div>
              </div>

            </draggable>

          </div>

        </template>
        <template v-slot:footer>
          <div v-if="user.user_id === account.user_id">
            <p class="tiny">Drag categories up or down then click save.</p>
            <div>
              <button class="btn-add" style="margin-right:8px;" v-on:click="saveSortedCategories">Save</button>
              <button class="btn-edit" v-on:click="sortCategories = false">Cancel</button>
            </div>
          </div>
          <p v-else class="tiny">Only account owners can sort categories.</p>
        </template>
      </modal>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showModal" @close="showModal = false">
        <template v-slot:header>
          <h3>{{ scorecard.scorecard_name }}</h3>
        </template>
        <template v-slot:body>
          <p>
            {{ scorecard.scorecard_description }}
          </p>
        </template>
        <template v-slot:footer>
          <p v-if="user.user_id === account.user_id" class="tiny">To edit this description, close this window and then click the Pencil icon.</p>
          <p v-else class="tiny"></p>
        </template>
      </modal>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showEdit" @close="showEdit = false">
        <template v-slot:header>
          <h3>Edit Board</h3>
        </template>
        <template v-slot:body>
          <div class="form-grp">
            <label for="scorecard_name">Name</label>
            <input type="text" v-model="scorecard.scorecard_name" name="scorecard_name" maxlength="144" />
          </div>
          <div class="form-grp">
              <label for="scorecard_description">Description</label>
              <textarea v-model="scorecard.scorecard_description" name="scorecard_description" />
          </div>
          <div style="margin:10px 0;">
            <div v-if="editmsg">{{editmsg}}</div>
          </div>
          <div class="form-group">
              <button style="padding:10px 20px ;" class="btn btn-primary" :disabled="loading || scorecard.deleted" @click="editScorecard">Update</button>
              |
              <a href="#" @click.prevent="showEdit = false" style="text-decoration:none;">Cancel</a>
          </div>
        </template>
        <template v-slot:footer>
          <div style="text-align: right;">
            <a class="tiny" v-on:click.prevent="advanced = !advanced">
              <span v-show="!advanced">Advanced Options</span>
              <span v-show="advanced">Close</span>
            </a>
          </div>
          <div v-show="advanced" style="text-align: center;border-top:1px solid #ccc;padding:20px 0;">
            <button v-on:click.prevent="cloneScorecard" :disabled="cloning" style="margin-right:20px;border: 1px solid #ccc;padding:10px 10px;background:#fff;color:#6AA84F;">Clone</button>
            <button @click.prevent="showEdit=false;showTransfer=true;startTransfer();" style="margin-right:20px;border: 1px solid #ccc;padding:10px 10px;background:#fff;color:#6AA84F;">Transfer</button>
            <button @click.prevent="deleteScorecard" v-show="scorecard.deleted === false" style="margin-right:0px;border: 1px solid #ccc;padding:10px 10px;background:#fff;color:#CC0000;">Archive</button>
            <button @click.prevent="deleteScorecard" v-show="scorecard.deleted === true" style="margin-right:0px;border: 1px solid #ccc;padding:10px 10px;background:#fff;color:#6AA84F;">Reactivate</button>
          </div>
        </template>
      </modal>

      <modal v-if="showTransfer" @close="showTransfer = false">
        <template v-slot:header>
          <h3>Transfer to another account</h3>
        </template>
        <template v-slot:body>
          <div style="height: 100px; overflow: scroll;">
            Choose an account:
            <div v-if="accounts.length > 0">
              <div class="row" v-for="acct in accounts" :key="acct.account_id">
                <div class="column" v-if="account.account_id != acct.account_id">
                  <a v-on:click.prevent="transferScorecard(acct.account_id, acct.account_name)">
                      {{ acct.account_name }} (owner)
                  </a>
                </div>
              </div>
            </div>
            <div v-if="memberships.length > 0">
              <div class="row" v-for="mems in memberships" :key="mems.account_id" style="background: #ccc;">
                <div class="column" v-if="account.account_id != mems.account_id">
                  <a v-on:click.prevent="transferScorecard(mems.account_id, mems.account_name)">
                      {{ mems.account_name }} (member)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <p class="tiny">Transferring changes ownership to the new account.</p>
        </template>
      </modal>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showVideo" @close="showVideo = false">
        <template v-slot:header>
          <h3>Foclarity Business Management Demo</h3>
        </template>
        <template v-slot:body>
          <iframe style="margin-top:10px;" width="100%" height="315" src="https://www.youtube.com/embed/yx35wgWOS34" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
        </template>
        <template v-slot:footer>
          <p>Foclarity helps you see the big picture... try it free!</p>
        </template>
      </modal>                

        <!-- use the modal component, pass in the prop -->
        <AddTask v-if="showAddTask" @close="showAddTask = false" v-bind:scid="scid" v-bind:uid="user.user_id" @newTask='taskrender += 1' />

    </div>

    <div style="text-align:center;margin-top:40px;" v-show="false">
      <h2>Need help getting started?</h2>
      <p>
          <a href="/contact">
              Schedule a Zoom Session
          </a>
      </p>
    </div>

  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { snapshotService, accountService, userService } from '../_services';
import { scorecardService } from "../_services/scorecard.service";
import ScorecardTasks from "@/components/includes/ScorecardTasks"
import AddTask from "@/components/includes/AddTask"
import CategoryMeasures from "@/components/includes/MeasuresList"
import modal from "@/components/includes/InfoModal"
import draggable from "vuedraggable";
import searchbar from "@/components/includes/SearchBar"
import ChatByTopic from "@/components/includes/ChatByTopic"

export default {
  title: 'Board | Foclarity',
  name: "Board",
  mounted: function() {
  },
  data () {
      return {
        scorecard: "",
        summary: "",
        summary_stream: [],
        summary_date: "",
        summary_waiting: "",
        show_long_summary: false,
        snapshots: [],
        notescore: 0,
        user: "",
        account: "",
        showTransfer: false,
        accounts: [],
        memberships: [],
        categories: [],
        first_category_id: -1,
        catscores: [],
        showChat: false,
        scid: this.$route.params.scorecard_id,
        scorecard_score: 0,
        measure_off: false,
        submitted: false,
        showModal: false,
        showEdit: false,
        showVideo: false,
        showAddTask: false,
        sortCategories: false,
        cloning: false,
        transferring: false,
        taskrender: 0,
        loading: true,
        returnUrl: '',
        error: '',
        help: false,
        message: '',
        emsg: '',
        editmsg: '',
        members: [],
        emlist: [],
        inviting: false,
        invite_email: "",
        show_invite_message: "",
        invite_message: "",
        emsent: '',
        toggle: false,
        active: false,
        new_account_id: 0,
        advanced: false,
        expandedGroup: [],
        expanded: false,
        report: '',
        reload: 0,

        // series and chart for the scorecard radar
        series: [],
        chartOptions: {
          chart: {
            height: 400,
            type: 'radar',
            toolbar: {
              show: false
            },
          },
          xaxis: {
            categories: ["one","two","three","four","five"]
          },
          legend: {
            show: true
          },
          theme: {
            palette: 'palette1'
          }
        },
        catkey: 0,

        // chart options for line charts
        chartOptionsLine: {
          chart: {
            height: 200,
            type: 'area',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          theme: {
            palette: 'palette1'
          },
          markers: {
            size: 5
          },
          //title: {
          //  text: 'Progress',
          //  align: 'left'
          //},
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: [
              this.chartdate(2), 
              this.chartdate(1), 
              this.chartdate(0)
            ],
          },
          yaxis: {
            max: 5,
            forceNiceScale: true
          }
        },
      }
  },
  methods: {
    // write a function that takes this.search and looks for snapshot.measure_name that includes this.search
    // then return the results into this.searchResults
    searchMeasures: function () {
      // don't perform the search until this.search has at least 3 characters
      if (this.search.length > 0) {
        // loop through categories and if any of the measure_names match, add them to the searchResults
        this.searchResults = []
        this.categories.forEach(category => {
          category.measures.forEach(measure => {
            if (measure.measure_name.toLowerCase().includes(this.search.toLowerCase())) {
              measure.category_id = category.category_id
              measure.category_name = category.category_name
              measure.scorecard_id = this.scorecard.scorecard_id
              measure.scorecard_name = this.scorecard.scorecard_name
              this.searchResults.push(measure)
            }
          })
        })
      } else {
        this.searchResults = []
      }
    },
    streamResponse: function (fullResponse) {
      const chunkSize = 10; // Customize this based on your needs
      for (let i = 0; i < fullResponse.length; i += chunkSize) {
        const chunk = fullResponse.substring(i, i + chunkSize);
        setTimeout(() => {
          this.summary_stream.push({ text: chunk, sender: 'bot' });
        }, i * 10); // Adjust timing as needed
      }
    },
    expandAll: function () {
      this.expanded = !this.expanded
      if (this.expanded) {
        this.expandedGroup = this.categories.map(c => c.category_id)
      } else {
        this.expandedGroup = []
      }
    },
    isExpanded(key) {
      return this.expandedGroup.indexOf(key) !== -1;
    },
    saveSortedCategories() {
      if(confirm("Sorting Categories is under construction so the new order will not be saved permanently at this time.")){
        this.sortCategories = false
      } else {
        this.sortCategories = false
      }
    },
    toggleExpansion(key) {
      if (this.isExpanded(key)) {
        this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1);
      } else {
        this.expandedGroup.push(key);
      }
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    startTransfer: function () {
      this.transferring = true ;
      accountService.readAccounts().then(data => {
        this.accounts = data
        this.showTransfer = true ;
      });
      accountService.readMemberships().then(data => {
        this.memberships = data
      });
    },
    transferScorecard: function (aid, aname) {
      if(confirm("Are you sure you want to transfer this scorecard to " + aname + "?")){
        this.transferring = true ;
        scorecardService.transferScorecard(this.scorecard.scorecard_id, aid)
          .then(
              data => {
                  if (data.message) {
                    this.$router.push("/dashboards")
                  }
              },
              error => {
                  alert(error)
                  this.transferring = false ;
              }
          );
      }
    },
    contactSupport: function () {
      // add time stamp to the subj
      var subj = "Beta Feedback" + " " + new Date().toISOString().slice(0, 19).replace('T', ' ') ;
      this.submitted = true ;

      if (this.message === "") {
        this.emsg = "" ;
        this.error = "Please enter a message" ;
        this.submitted = false ;
        return ;
      } 

      let page_details = this.scorecard.scorecard_name + " (" + this.scorecard.scorecard_id + ") "
      let account_details = this.account.account_name + " (" + this.account.account_id + ") "
      let user_details = this.user.first_name + " " + this.user.last_name + " (" + this.user.user_id + ") "
      let message = "<br><br>" + this.message + "<br><br>" + user_details + "<br>" + account_details + "<br>" + page_details

      accountService.contactSupport(subj, message).then(
        data => {
          if (data.status == 'failed') {
            this.submitted = false ;
            this.error = 'failed to send message' ;
          } else {
            this.error = ""
            this.message = ""
            this.submitted = false ;
            this.emsg = "Feedback sent, thank you!"
            this.help = false;
          }
      },
      error => {
        this.error = error
        this.loading = false
      });
    },
    cloneScorecard: function () {
      if(confirm("Are you sure you want to clone this scorecard into another account?")){
        this.cloning = true ;
        scorecardService.cloneScorecard(this.scorecard.scorecard_id)
          .then(
              data => {
                  if (data.message) {
                    this.$router.push("/dashboards")
                  }
              },
              error => {
                  alert(error)
                  this.cloning = false ;
              }
          );
      }
    },
    editScorecard: function () {
        //console.log("editing")
        scorecardService.updateScorecard(this.scorecard.scorecard_id, this.scorecard.scorecard_name, this.scorecard.scorecard_description)
            .then(
                data => {
                    this.editmsg = data.scorecard_name + ' Saved. Close this window to continue.';
                    this.showEdit = false ;
                },
                error => {
                    this.editmsg = error + ' Please close and try again.'
                }
            );
    },
    deleteScorecard: function () {
        //console.log("deleting")
        if(this.scorecard.deleted) {
          if(confirm("Re-activate this scorecard?")){
            scorecardService.deleteScorecard(this.scorecard.scorecard_id).then(
              data => {
                console.log(data.deleted)
                this.$router.push("/dashboards")
              },
              error => {
                console.log(error)
                this.editmsg = error + ' Please close and try again.'
              })
          }
        } else {
          if(confirm("Are you sure you want to archive this scorecard?")){
            scorecardService.deleteScorecard(this.scorecard.scorecard_id).then(
              data => {
                console.log(data.deleted)
                this.$router.push("/dashboards")
              },
              error => {
                console.log(error)
                this.editmsg = error + ' Please close and try again.'
              })
          }
        }
    },
    makeinvite: function () {
      this.inviting = true
      if (this.account.user_id === this.user.user_id) {
        // user is account owner
        accountService.createInvitation(this.invite_email).then(
          data => {
            this.invite_email = ""
            this.invite_message = data.message
            this.show_invite_message = true ;
            setTimeout(() => this.show_invite_message = false, 3000);
            this.getOpenInvitations(); // update the invitations list
            this.inviting = false;
          },
          error => {
            this.invite_email = ""
            this.invite_message = error
            this.show_invite_message = true ;
            setTimeout(() => this.show_invite_message = false, 3000);
            this.inviting = false;
          }
        )
      } else {
        // user is not account owner
        this.invite_message = "Only account owners can invite at this time."
        this.show_invite_message = true ;
        setTimeout(() => this.show_invite_message = false, 3000);
        this.inviting = false;
      }
      this.inviting = false ;
    },
    getsavedsummary: function () {
      accountService.getSavedSummary(this.scid).then(
        data => {
            if (data.status === 'success') {
              // console.log(data)
              if (data.summary === undefined) {
                this.summary = ""
              } else {
                this.summary = data.summary.summary ;
                this.summary_date = data.summary.created_at ;
              }
              this.summary_waiting = "" ;
            } else {
              this.summary = ""
            }
          },
          error => {
            this.summary = error
          }
      )
    },
    summarizeStatus: function () {
      if(confirm("Thank you for using Foclarity AI. You have 7 credits remaining.")){
        this.summary_waiting = "generating report..."
        accountService.summarize(this.scid).then(
          data => {
            if (data.status === 'success') {
              // console.log(data)
              if (data.summary === undefined) {
                this.summary = "Congratulations! You have a lot of status updates! Unfortunately, we can't process that much data with AI yet, but here's a radar diagram showing your progress."
              } else {
                this.summary = data.summary.summary ;
                this.summary_date = data.summary.created_at ;
                this.summary_stream = [] ;
                this.streamResponse(this.summary)
              }
              this.summary_waiting = "" ;
            } else {
              this.summary = data.message
            }
          },
          error => {
            this.summary = error
          }
        )
      }
    },    
    sendStatus: function () {
      if(confirm("Send an email status report to the selected collaborators?")){
        accountService.emStatus(this.scid, this.emlist).then(
          data => {
            if (data.status == 'success') {
              this.emsent = 'Email has been sent'
              setTimeout(() => this.emsent = '', 3000);
            } else {
              this.emsent = data.message
              setTimeout(() => this.emsent = '', 3000);
            }
          },
          error => {
            this.emsent = error
            setTimeout(() => this.emsent = '', 3000);
          }
        )
      }
    },
    getMembers: function () {
      accountService.readAccountMembers().then(
        data => {
          this.members = data.members
        }
      )
    },
    toggleemlist: function(member, event) {
      if (event.target.checked) {
        this.emlist.push(member);
      } else {
        this.emlist = this.emlist.filter(m => m.member_id != member.member_id);
      }
      // console.log(this.emlist);
    },
    activeAccount() {
      this.account = accountService.getActiveAccount() ;
      this.user = userService.getActiveUser() ;
    },
    bulletcolor: function (score) {
      if (score === 1) {
        return {'emerging': true}
      } else if (score === 2) {
        return {'below-average': true}
      } else if (score === 3) {
        return {'average': true}
      } else if (score === 4) {
        return {'above-average': true}
      } else if (score === 5) {
        return {'leadership': true}
      } else {
        return 'emerging'
      }
    },
    cardcolor: function (score) {
      // console.log(score)
      if (score < 0.2) {
        return {'emerging-bullet': true}
      } else if (score >= 0.3 && score < 0.5) {
        return {'below-average-bullet': true}
      } else if (score >= 0.5 && score < 0.6) {
        return {'average-bullet': true}
      } else if (score >= 0.7 && score < 0.8) {
        return {'above-average-bullet': true}
      } else if (score >= 0.9) {
        return {'leadership-bullet': true}
      } else {
        return 'emerging-bullet'
      }
    },
    chartdate: (past) => {
      var d = new Date();
      d.setMonth(d.getMonth() - past);
      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sep";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";
      var n = month[d.getMonth()];
      return n ;
    },
    construction: () => {
      alert('Prioritizing Categories is under construction, but it is on the roadmap. Look for it soon.')
    },
    switchCategory: function (category_id) {
      scorecardService.switchCategory(category_id).then(this.$router.push("scorecards/"+this.scid+"/categories/"+category_id))
    },
    getlinechartseries: function(c) {
      var obj = this.catscores.find(obj => obj.category_name == c);
      var score = 0
      try {
        score = obj.score
      } catch {
        score = 0
      }
      var series = [{
                name: "Score",
                data: [0, 0, score]
            }]
      return series
    },
    catscore: function (c) {
      var obj = this.catscores.find(obj => obj.category_name == c);
      var score = 0
      try {
        score = obj.score
      } catch {
        score = 0
      }

      if (score < 1.5) {
        return {'emerging': true}
      } else if (score >= 1.5 && score < 2.5) {
        return {'below-average': true}
      } else if (score >= 2.5 && score < 3.5) {
        return {'average': true}
      } else if (score >= 3.5 && score < 4.5) {
        return {'above-average': true}
      } else if (score >= 4.5) {
        return {'leadership': true}
      } else {
        return 'emerging'
      }

    },
    getScorecard: function () {
      this.loading = true ;
      scorecardService.readScorecard(this.scid).
      then(
        data => {
          // console.log(data.scorecard.scorecard_id)
          this.scorecard = data.scorecard
          window.document.title = this.scorecard.scorecard_name + ' | Dashboard'
          if (data.categories.length > 0) {
            // console.log(data.categories)
            this.categories = data.categories
            this.categories.reverse();
            this.first_category_id = this.categories[0].category_id;
            this.expandAll() ; // comment this out to hide all tiles on load
          } else {
            console.log('no categories')
          }
        }).
      then(() => {
        snapshotService.readScorecardSnapshots(this.scid).then(
        data => {
          this.snapshots = data.snapshots
          this.scorecard_score = data.scorecard_score
          // see if you can create a list of categories for the chart
          var newcats = [...new Set(this.categories.map(x => x.category_name))]
          var newcatstrimmed = [...new Set(this.categories.map(x => (x.category_name.substring(0,12) + "...")))]
          this.chartOptions.xaxis.categories = newcatstrimmed
          this.catkey ++
          // console.log('982 - ' + this.catkey)
          //console.log(newcats)

          // now see if you can total up the category scores and divide by the array length
          // for each category, sum rank_scores and divide by number of snapshots
          // then push that into the newscores array
          var newscores = [] ;
          var catscores = [] ;
          var cat
          for (cat of newcats) {
            //console.log(cat)
            var ss
            var score = 0
            var sscats = 0
            for (ss of data.snapshots) {
              if (ss.category_name == cat) {
                score = score + ss.rank_score
                sscats = sscats + 1
                //console.log(score)
              }
            }
            if (sscats == 0) {
              sscats = 1
            }
            score = score / sscats
            //console.log(score)
            newscores.push(score)
            catscores.push({"category_name":cat, "score": score})
          }

          this.catscores = catscores ;
          
          // add in bestscores for the graph size
          var bestscores = []
          // var bestscore = 0
          var i ;
            for (i = 0; i < newscores.length; i++) {
              bestscores.push(5)
              // bestscore = bestscore + this.categories.length
            }
          var bestave = 5 //bestscore / bestscores.length
          var latest = {"name": this.scorecard.scorecard_name+" score: "+this.scorecard_score, "data": newscores}
          var best = {"name": "Target score: "+bestave, "data": bestscores}
          this.series.push(latest)
          this.series.push(best)
          this.catkey ++
          // console.log('1023 - ' + this.catkey)

          this.loading = false
          // this.catkey += 1 ;
        }
      ).
      catch(error => {
          this.error = error
          this.loading = false
      })
    })
    }
  },
  components: {
    ScorecardTasks,
    AddTask,
    CategoryMeasures,
    modal,
    draggable,
    searchbar,
    ChatByTopic
  },
  created () {
    this.getScorecard() ;
    this.activeAccount() ;
    this.getMembers() ;
    this.getsavedsummary() ;
    // if (window.innerWidth > 800) {
    //   this.measure_off = false
    // }
  },
  watch: {
    newTask: function () {
      this.taskrender += 1;
    },
    $route(to, from) {
      if (to.params.scorecard_id !== from.params.scorecard_id) {
        this.scid = to.params.scorecard_id
        this.taskrender ++ ;
        this.chartOptions = {
          chart: {
            height: 400,
            type: 'radar',
            toolbar: {
              show: false
            },
          },
          xaxis: {
            categories: ["one","two","three","four","five"]
          },
          legend: {
            show: true
          },
          theme: {
            palette: 'palette1'
          }
        } ;
        this.series = [] ;
        this.catkey ++ ;
        // console.log('1062 - ' + this.catkey)

        this.getScorecard() ;
        this.activeAccount() ;
        this.getMembers() ;
        this.getsavedsummary() ;
      }
    }
  },
  computed: {
    filteredSnapshots() {
      if (this.notescore === 0) {
        var newsnaps = this.snapshots
        return newsnaps.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reverse() ;
      } else {
        return this.snapshots.filter((note) => {return note.rank_score === this.notescore}, false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.boxcard {
  max-width: 400px ;
  display: flex ;
}

.arrow_up {
  transform: rotate(270deg);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.catdesc {
  flex-shrink: 2;
  flex-grow: 1;
  font-size:14px;
  line-height:26px;
  cursor: pointer;
  padding:0;
  margin:0 auto 0 20px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  color:#666;
  @media only screen and (max-width: 440px) {
    display: none;
  }
}

.activefilter {
  color: #333;
  text-decoration: none;
}

.searchbar {
  display: block;
  position: absolute;
  top:-15px;
  right: 0;
  z-index: 1000;
  margin:0;
  width: 400px;
  @media only screen and (max-width: 440px) {
    width:100%;
  }
  // border: 1px solid #ccc;
  // border-radius: 4px;
  background: #fff;
  & .search {
    width: 100%;
    // border: 1px solid #ccc;
    // border-radius: 4px;
    font-size: 16px;
    outline: none;
    & input {
      width: 100%;
      font-size: 16px;
      padding: 5px;
      outline: none;
    }
  }
  & .search-results {
    display: block;
    font-size: 16px;
    outline: none;
    z-index: 10000;
    & .search-result {
      width: 100%;
      background: #fff;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
      & a {
        display: block;
        text-decoration: none;
      }
    }
  }
}
</style>

